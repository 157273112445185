var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-full min-contents" },
    [
      _c("div", { staticClass: "container mx-auto my-4 mb-8 px-4 md:px-0" }, [
        _c(
          "div",
          {
            staticClass:
              "w-full bg-white overflow-hidden shadow rounded-lg mb-8",
          },
          [
            _vm.featuredList
              ? _c(
                  "div",
                  { staticClass: "p-6 md:p-2 grid grid-cols-6 gap-6" },
                  [
                    _c("p", { staticClass: "col-span-6 font-bold" }, [
                      _vm._v(
                        _vm._s(
                          _vm.featuredList.translations[_vm.$i18n.locale]
                            .list_name
                        )
                      ),
                    ]),
                    _c(
                      "p",
                      {
                        staticClass:
                          "col-span-6 text-sm break-words whitespace-pre-wrap",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.featuredList.translations[_vm.$i18n.locale]
                                .description
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "span",
                      { staticClass: "col-span-6 md:col-span-3" },
                      [
                        _c(
                          "LinkButton",
                          {
                            attrs: {
                              classList: "px-2 py-2 text-center w-full rounded",
                            },
                            on: {
                              clickButton: function ($event) {
                                return _vm.addRecommendedToCart()
                              },
                            },
                          },
                          [_vm._v(" add recommended to cart ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "span",
                      { staticClass: "col-span-6 md:col-span-3" },
                      [
                        _c(
                          "LinkButton",
                          {
                            attrs: {
                              classList: "px-2 py-2 text-center w-full rounded",
                            },
                            on: {
                              clickButton: function ($event) {
                                return _vm.addAllToCart()
                              },
                            },
                          },
                          [_vm._v(" add all to cart ")]
                        ),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          { staticClass: "mb-4 grid grid-cols-12 gap-4" },
          [
            _vm.featuredList && _vm.featuredList.movies.length
              ? _vm._l(_vm.featuredList.movies, function (movie, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass:
                        "col-span-12 md:col-span-4 bg-white overflow-hidden shadow rounded p-2",
                    },
                    [
                      _c(
                        "FlMovieImage",
                        {
                          staticClass: "cursor-pointer mb-2",
                          attrs: { value: _vm.movieImage(movie) },
                          on: {
                            click: function ($event) {
                              return _vm.showDetail(movie)
                            },
                          },
                        },
                        [
                          movie.recommend
                            ? _c("div", { staticClass: "ribbon_outer" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "ribbon bg-red-500 text-white font-bold",
                                  },
                                  [_vm._v("recommend")]
                                ),
                              ])
                            : _vm._e(),
                          movie.visible_screener
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "absolute inset-0 flex justify-center cursor-pointer",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      _vm.currentScreener = movie
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "display-block h-auto m-auto",
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticClass:
                                            "h-20 w-20 text-red-500 m-auto",
                                          attrs: {
                                            xmlns: "http://www.w3.org/2000/svg",
                                            viewBox: "0 0 20 20",
                                            fill: "currentColor",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              "fill-rule": "evenodd",
                                              d: "M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z",
                                              "clip-rule": "evenodd",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _vm.currentScreener === movie
                        ? _c("iframe", {
                            staticClass:
                              "display-block w-full h-full absolute inset-0",
                            attrs: {
                              src: _vm.convertPreviewUrl(movie.screener_url),
                              frameborder: "0",
                              allow: "autoplay; encrypted-media",
                              allowfullscreen: "",
                            },
                          })
                        : _vm._e(),
                      _c("div", { staticClass: "col-span-12" }, [
                        _c(
                          "p",
                          { staticClass: "w-full font-bold mb-2 relative" },
                          [
                            _vm._v(" " + _vm._s(movie.film_name) + " "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "text-right text-xs absolute bottom-0 right-0",
                              },
                              [_vm._v(_vm._s(movie.director))]
                            ),
                          ]
                        ),
                      ]),
                      movie.visible_screener
                        ? [
                            movie.screener_password &&
                            movie.screener_password.length
                              ? [
                                  _c("div", { staticClass: "col-span-12" }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-xs pl-2 font-bold select-none bg-light-yellow rounded-full",
                                      },
                                      [
                                        _vm._v(
                                          " password is : " +
                                            _vm._s(movie.screener_password) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              : [_c("div", { staticClass: "col-span-12 h-4" })],
                            _c("div", { staticClass: "col-span-12 py-1" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "bg-blue-50 border border-blue-300 rounded-full grid grid-cols-2",
                                },
                                [
                                  movie.screener_password &&
                                  movie.screener_password.length
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-1 cursor-pointer hover:bg-blue-500 hover:text-white rounded-full",
                                            on: {
                                              click: function ($event) {
                                                return _vm.copyScreenerPassword(
                                                  movie
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "rounded-full w-8 h-8 border bg-gray-500 p-0.5 m-1 inline-block",
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass:
                                                      "h-6 w-6 inline text-white",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      fill: "none",
                                                      viewBox: "0 0 24 24",
                                                      stroke: "currentColor",
                                                      "stroke-width": "2",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "stroke-linecap":
                                                          "round",
                                                        "stroke-linejoin":
                                                          "round",
                                                        d: "M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-xs font-bold",
                                              },
                                              [
                                                _vm._v(" 1: "),
                                                index == _vm.copiedIndex
                                                  ? _c("span", [
                                                      _vm._v("copied!"),
                                                    ])
                                                  : _c("span", [
                                                      _vm._v("copy password"),
                                                    ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-1 cursor-pointer hover:bg-blue-500 hover:text-white rounded-full",
                                            on: {
                                              click: function ($event) {
                                                _vm.currentScreener = movie
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticClass:
                                                  "h-10 w-10 text-red-500 inline",
                                                attrs: {
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                  viewBox: "0 0 20 20",
                                                  fill: "currentColor",
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    "fill-rule": "evenodd",
                                                    d: "M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z",
                                                    "clip-rule": "evenodd",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-xs font-bold",
                                              },
                                              [
                                                _vm._v(
                                                  "2: click to play screener"
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    : [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-2 cursor-pointer rounded-full hover:bg-blue-500 hover:text-white",
                                            on: {
                                              click: function ($event) {
                                                _vm.currentScreener = movie
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticClass:
                                                  "h-10 w-10 text-red-500 inline",
                                                attrs: {
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                  viewBox: "0 0 20 20",
                                                  fill: "currentColor",
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    "fill-rule": "evenodd",
                                                    d: "M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z",
                                                    "clip-rule": "evenodd",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-xs font-bold",
                                              },
                                              [_vm._v("click to play screener")]
                                            ),
                                          ]
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ]),
                          ]
                        : _c("div", { staticClass: "w-full h-16 mb-0.5" }),
                      _c("div", { staticClass: "grid gap-2 grid-cols-2" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "col-span-1 py-1 font-bold text-sm bg-off-yellow hover:bg-off-yellow-lighter focus:bg-light-yellow-darker rounded",
                            on: {
                              click: function ($event) {
                                return _vm.showDetail(movie)
                              },
                            },
                          },
                          [_vm._v(" show detail ")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass:
                              "col-span-1 py-1 font-bold text-sm bg-off-yellow hover:bg-off-yellow-lighter focus:bg-light-yellow-darker rounded",
                            on: {
                              click: function ($event) {
                                return _vm.addToCart(movie)
                              },
                            },
                          },
                          [_vm._v(" add to cart ")]
                        ),
                      ]),
                    ],
                    2
                  )
                })
              : [
                  _c("p", { staticClass: "col-span-12 text-center text-sm" }, [
                    _vm._v(_vm._s(_vm.stateMessage)),
                  ]),
                ],
          ],
          2
        ),
      ]),
      _c("NoticeModal", {
        attrs: {
          modalName: "requestLogin",
          title: "Please login.",
          contents:
            "You can request a quote for the film to the license holder directly after you login.",
          buttons: ["not now", "log me in"],
        },
        on: {
          click: function ($event) {
            return _vm.showLoginWithForward()
          },
        },
      }),
      _c("NoticeModal", {
        attrs: {
          modalName: "addedRecommend",
          contents: "added recommended movies to cart.",
          buttons: ["OK"],
        },
      }),
      _c("NoticeModal", {
        attrs: {
          modalName: "addedAll",
          contents: "added all movies in this list to cart.",
          buttons: ["OK"],
        },
      }),
      _c("NoticeModal", {
        attrs: {
          modalName: "addedSingle",
          contents: "added movie to cart.",
          buttons: ["OK"],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }